<template>
  <div class="grid">
    <Toast />

    <div class="col-12" v-if="pedidoData && loadingData === false" >
      <h6 v-if="pedidoData.id">Editando Pedido {{ pedidoData.codexterno }} <span class="text-400">({{ pedidoData.id }})</span>
      <span :class="'ml-3  pedido-badge status-' + (pedidoData.status ? pedidoData.status.toLowerCase() : '')"> {{nomeStatus(pedidoData.status)}}</span>
      <span :class="'ml-3  cliente-badge status-' + (pedidoData.stscrg ? pedidoData.stscrg.toLowerCase() : '')"> {{ pedidoData.stscrg != null ? 'Em Carga' : '' }}</span>
      <span v-if="pedidoData.stserp" :class="'ml-3  pedido-badge status-' + (pedidoData.stserp ? pedidoData.stserp.toLowerCase() : '')"> {{nomeStatusERP(pedidoData.stserp)}}</span>
      </h6>
      <h6 v-else>Novo Pedido</h6>
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Salvar" icon="pi pi-save" class="p-button-success mr-2" @click="savePedido" :loading="loadingBtnSave" v-if="pedidoData && pedidoData.status === 'EL'" />
            <Button label="Exportar" icon="pi pi-download" class="mr-2 p-button-outlined" @click="imprimitPDF" v-if="pedidoData.id !== 0" :loading="loadingPDF"/>
            <Button label="Notas Fiscais / XML" icon="pi pi-download" class="mr-2 p-button-outlined" @click="downloadNotaExml" :loading="loadingNota" v-if="pedidoData && pedidoData.status === 'FA'"/>
            <Button label="Confirmar Pedido" icon="pi pi-check" class="mr-2 p-button-outlined" @click="confPedido()" v-if="pedidoData && pedItensData.length > 0 && pedidoData.status === 'EL'"/>
            <Button label="Excluir Pedido" icon="pi pi-trash" class="mr-2 p-button-outlined" @click="excluiPedido()" v-if="pedidoData && pedItensData.length > 0 && pedidoData.status === 'EL'" />
            <Button label="Copiar Pedido" icon="pi pi-copy" class="mr-2 p-button-outlined" @click="copiarPedido()" v-if="pedidoData && pedidoData.id > 0" :loading="loadingCopy"/>
            <Button label="Exportar Pedido WS" icon="pi pi-arrow-up-right" class="mr-2 p-button-outlined" @click="exportPedidoWS()" v-if="pedidoData && pedidoData.stserp === 'ER'" />
            <Button label="Novo Produto" icon="pi pi-plus" class="mr-2 p-button-outlined" @click="novoProduto()" v-if="pedidoData && pedidoData.status === 'EL'" />
          </div>
        </template>
        <template v-slot:end>
          <Button label="Voltar" icon="pi pi-backward" class="p-button-outlined mr-2" @click="$router.go(-1)" />
        </template>
      </Toolbar>
    </div>

    <div class="col-12" >

      <div  class="custom-skeleton p-4" v-if="loadingData === true">
        <Skeleton width="100%" height="50px"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
        <Skeleton width="100%" height="50px" class="mt-3"></Skeleton>
      </div>

      <TabView v-if="loadingData === false" :activeIndex="activeIndex" @tab-change='alterouTab'>

        <TabPanel>

          <template #header>
            <span>Dados Principais</span>
            <i class="pi pi-shopping-cart ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid">

            <div class="field col-12 md:col-6">
              <label for="tipo">Tipo de Operação</label>
              <Tag class="ml-2 mr-2"
                   v-if="pedtipoData && pedtipoData.desconto > 0"
                   severity="warning"
                   :value="' - ' + formatDecimal(pedtipoData.desconto) + ' %'">
              </Tag>
              <AutoComplete
                  placeholder="Pesquisar tipo de operação"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValuePedTipo"
                  :suggestions="autoFilteredPedTipo"
                  @complete="searchPedTipo($event)"
                  field="descricao"
                  :class="validPedido && !pedidoData.tipo ? 'p-invalid' : ''"
                  :disabled="pedItensData.length > 0 || (pedidoData != null && pedidoData.status !== 'EL')"
                  @item-select="selecionaPedTipo($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 60%">{{slotProps.item.descricao}}</div>
                    <div style="width: 10%">{{slotProps.item.desconto}}</div>
                  </div>
                </template>
              </AutoComplete>
              <small class="p-invalid" v-if="validPedido && !pedidoData.tipo">Campo obrigatório.</small>
            </div>


            <div class="field col-12 md:col-2">

            </div>

            <div class="field col-12 md:col-2">

            </div>

            <div class="field col-12 md:col-2">
              <label for="dtemissao">Emissão</label>
              <Calendar :showIcon="true"
                        :showButtonBar="true"
                        v-model="pedidoData.dtemissao"
                        dateFormat="dd/mm/yy"
                        :disabled="true"></Calendar>
            </div>


            <div class="field col-12 md:col-9">
              <label for="cliente">Cliente</label>
              <AutoComplete
                  placeholder="Pesquisar cliente (mínimo 3 caracteres)"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueCliente"
                  :suggestions="autoFilteredCliente"
                  @complete="searchCliente($event)"
                  field="nome"
                  :class="validPedido && !pedidoData.cliente ? 'p-invalid' : ''"
                  :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                  @item-select="selecionaCliente($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 10%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 60%">{{slotProps.item.nome}}</div>
                    <div style="width: 20%">{{slotProps.item.cnpj}} </div>
                    <div style="width: 20%">
                      <span :class="'ml-3  cliente-badge status-' + (slotProps.item.status ? slotProps.item.status.toLowerCase() : '')"> {{ slotProps.item.status }}</span>
                    </div>
                    <div style="width: 20%">{{slotProps.item.cidade}}</div>
                  </div>
                </template>
              </AutoComplete>
              <small class="p-invalid" v-if="validPedido && !pedidoData.cliente">Campo obrigatório para salvar pedido.</small>
            </div>



            <div class="field col-3 md:col-3">
              <label for="limite">Limite</label>
              <div class="p-inputgroup">
                <InputText id="limite" type="text" v-model="clienteData.limite" readonly/>
              </div>
            </div>

            <div class="field col-12 md:col-3">
              <label for="condpgto">Prazo de Pagamento</label>
              <Tag class="ml-2 mr-2"
                   v-if="condpgtoData && condpgtoData.desconto > 0"
                   severity="warning"
                   :value="' - ' + formatDecimal(condpgtoData.desconto) + ' %'">
              </Tag>
              <AutoComplete
                  placeholder="Pesquisar prazo"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueCondicao"
                  :suggestions="autoFilteredCondicao"
                  @complete="searchCondicao($event)"
                  field="descricao"
                  :class="(validPedido || validConfPedido) && !pedidoData.condpgto ? 'p-invalid' : ''"
                  :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                  @item-select="selecionaCondicao($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 70%">{{slotProps.item.descricao}}</div>
                  </div>
                </template>
              </AutoComplete>
              <small class="p-invalid" v-if="(validPedido || validConfPedido) && !pedidoData.condpgto">Campo obrigatório para salvar ou confirmar pedido.</small>
            </div>

            <div class="field col-12 md:col-3" >
              <label for="desconto">Desconto Manga (Máx: 20%)</label>
              <InputNumber id="descond"
                           v-model="pedidoData.desconto"
                           mode="decimal"
                           locale="pt-BR"
                           :max="20"
                           :min="0"
                           @change="calculaTotalPedido"
                           :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                           :minFractionDigits="2"/>
              <Slider v-model="pedidoData.desconto"
                      :step="0.1"
                      :min="0"
                      :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                      :max="20" />
            </div>

            <div class="field col-12 md:col-3">
              <label for="codtab">Tabela de Preço</label>
              <Tag class="ml-2 mr-2"
                   v-if="(clienteData && clienteData.proprecos) || (pedtipoData && pedtipoData.codtab)"
                   severity="info"
                   :value="clienteData && clienteData.proprecos ? 'CLIENTE' : 'OPERAÇÂO'">
              </Tag>
              <AutoComplete
                  placeholder="Pesquisar tabela"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueCodTab"
                  :suggestions="autoFilteredCodTab"
                  @complete="searchCodTab($event)"
                  field="descricao"
                  :disabled="true"
                  @item-select="selecionaCodTab($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 70%">{{slotProps.item.descricao}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>


            <div class="field col-12 md:col-3">
              <label for="repres">Vendedor</label>
              <AutoComplete
                  placeholder="Pesquisar vendedor"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueRepres"
                  :suggestions="autoFilteredRepres"
                  @complete="searchRepres($event)"
                  field="descricao"
                  :class="validConfPedido && !pedidoData.repres ? 'p-invalid' : ''"
                  :disabled="(pedidoData != null && pedidoData.status !== 'EL') || (userData != null && userData.tipo === 'repre')"
                  @item-select="selecionaRepres($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 70%">{{slotProps.item.descricao}}</div>
                  </div>
                </template>
              </AutoComplete>
              <small class="p-invalid" v-if="validConfPedido && !pedidoData.repres">Campo obrigatório para confirmação de pedido.</small>
            </div>

            <div class="col-12 md:col-3">
              <h6>Tipo de Frete</h6>
              <div class="grid">
                <div class="col-12 md:col-3">
                  <div class="field-radiobutton">
                    <RadioButton id="frete_cif" name="CIF" value="C" v-model="pedidoData.frete" />
                    <label for="frete_cif">CIF</label>
                  </div>
                </div>
                <div class="col-12 md:col-3">
                  <div class="field-radiobutton">
                    <RadioButton id="frete_fob" name="FOB" value="F" v-model="pedidoData.frete" />
                    <label for="frete_fob">FOB</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 md:col-3" v-if="pedidoData.frete === 'F'">
              <h6>Tipo de FOB</h6>
              <div class="grid">
                <div class="col-12 md:col-3">
                  <div class="field-radiobutton">
                    <RadioButton id="fob_1" name="Envia" value="1" v-model="pedidoData.tpfob" />
                    <label for="fob_1">Envia</label>
                  </div>
                </div>
                <div class="col-12 md:col-3">
                  <div class="field-radiobutton">
                    <RadioButton id="fob_2" name="Retira" value="2" v-model="pedidoData.tpfob" />
                    <label for="fob_2">Retira</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="field col-2">
              <label for="percfrete">% Frete</label>
              <InputNumber id="percfrete"
                           v-model="pedidoData.percfrete"
                           mode="decimal"
                           locale="pt-BR"
                           :min="0"
                           :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                           :minFractionDigits="2"/>
            </div>


              <div class="field col-12 md:col-2">
                  <label for="refer">Referência</label>
                  <InputText id="refer"
                             type="text"
                             :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                             v-model="pedidoData.refer"/>
              </div>

            <div class="field col-12 md:col-2">
              <label for="ordemcompra">Ordem Compra</label>
              <InputText id="ordemcompra"
                         type="text"
                         :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                         v-model="pedidoData.ordemcompra"/>
            </div>

            <div class="field col-12 md:col-2" v-if="pedidoData && pedidoData.frete === 'C'">

            </div>





            <div class="field col-6">
              <label for="obs">Observações</label>
              <Textarea id="obs"
                        rows="4"
                        :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                        v-model="pedidoData.obs"/>
            </div>

            <div class="field col-12 md:col-3">
              <label for="transp">Transportadora</label>
              <AutoComplete
                  placeholder="Pesquisar transportadora"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueTransp"
                  :suggestions="autoFilteredTransp"
                  @complete="searchTransp($event)"
                  field="descricao"
                  :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                  @item-select="selecionaTransp($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 70%">{{slotProps.item.descricao}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>


            <div class="field col-12 md:col-3">
              <label for="transp">Transportadora Redespacho</label>
              <AutoComplete
                  placeholder="Pesquisar transportadora"
                  :dropdown="true"
                  :multiple="false"
                  v-model="selectedAutoValueTranspRedesp"
                  :suggestions="autoFilteredTranspRedesp"
                  @complete="searchTranspRedesp($event)"
                  field="descricao"
                  :disabled="pedidoData != null && pedidoData.status !== 'EL'"
                  @item-select="selecionaTranspRedesp($event)">
                <template #item="slotProps" style="width: 20%">
                  <div class="p-fluid formgrid grid">
                    <div style="width: 30%">{{slotProps.item.codexterno}}</div>
                    <div style="width: 70%">{{slotProps.item.descricao}}</div>
                  </div>
                </template>
              </AutoComplete>
            </div>

            <div class="field col-12 md:col-12">
              <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
                <div class="grid">

                  <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                      <div class="flex justify-content-between mb-3">
                        <div>
                          <span class="block text-500 font-medium mb-3">Comissão ({{ percComissao > 0 || percComissao > 0 ? formatDecimal(percComissao) + ' %' : ' 0 %' }})</span>
                          <div class="text-900 font-medium text-xl">{{ valorComissao > 0 || valorComissao > 0 ? formatCurrency(valorComissao) : 'R$ 0,00' }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-wallet text-blue-500 text-xl"></i>
                        </div>
                      </div>
                      <span class="text-green-500 font-medium">{{ faixaComissao ? faixaComissao : 'Não definido' }} </span>
                      <span class="text-500"></span>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                      <div class="flex justify-content-between mb-3">
                        <div>
                          <span class="block text-500 font-medium mb-3">Peso Bruto Total</span>
                          <div class="text-900 font-medium text-xl">{{ pedidoData && pedidoData.peso > 0 ? formatDecimal(pedidoData.peso) : '0,00' }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-th-large text-cyan-500 text-xl"></i>
                        </div>
                      </div>
                      <span class="text-green-500 font-medium">{{ pedidoData && pedidoData.peso > 0 ? formatDecimal(pedidoData.peso) : '0,00' }}</span>
                      <span class="text-500"> líquido</span>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                      <div class="flex justify-content-between mb-3">
                        <div>
                          <span class="block text-500 font-medium mb-3">Desconto ({{ percDescPraticado > 0 || percDescPraticado > 0 ? formatDecimal(percDescPraticado) + ' %' : '0 %' }}) <span class="text-700"> Sacrif. ({{ percDescItens > 0 || percDescItens > 0 ? formatDecimal(percDescItens) + ' %' : '0 %' }})</span></span>
                          <div class="text-900 font-medium text-xl">{{ valorDescPedTipo > 0 || valorDescCond > 0 || valorDescManga > 0 ? formatCurrency(valorDescPedTipo + valorDescCond + valorDescManga) : 'R$ 0,00' }} </div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-sort-amount-down text-orange-500 text-xl"></i>

                        </div>
                      </div>
                      <span class="text-orange-500 font-medium">Mostruário {{ valorDescPedTipo > 0 ? formatCurrency(valorDescPedTipo) : 'R$ 0,00' }} | Prazo {{ valorDescCond > 0 ? formatCurrency(valorDescCond) : 'R$ 0,00' }}  | Manga {{ valorDescManga > 0 ? formatCurrency(valorDescManga) : 'R$ 0,00' }} </span>
                      <span class="text-500"></span>
                    </div>
                  </div>

                  <div class="col-12 md:col-6 lg:col-3">
                    <div class="surface-card shadow-2 p-3 border-round">
                      <div class="flex justify-content-between mb-3">
                        <div>
                          <span class="block text-500 font-medium mb-3">Valor Total Líquido</span>
                          <div class="text-900 font-medium text-xl">{{ valorLiqPed ? formatCurrency(valorLiqPed) : 'R$ 0,00' }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
                          <i class="pi pi-dollar text-green-500 text-xl"></i>
                        </div>
                      </div>
                      <span class="text-green-500 font-medium">{{ valorBrtPed ? formatCurrency(valorBrtPed) : 'R$ 0,00' }} </span>
                      <span class="text-500"> (valor bruto produtos)</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>


            <div class="field col-12">

            <DataTable
                v-if="pedItensData"
                :value="pedItensData"
                :scrollable="false"
                scrollHeight="400px"
                :loading="loadingOcors"
                scrollDirection="both"
                :style="pedItensData && pedItensData.length > 0 ? 'min-height: ' + pedItensData.length * 80 + 'px;' : ''"
                class="mt-3">

              <Column header="Ações" class="text-right" :style="{width:'100px'}" v-if="pedidoData.status === 'EL'">
                <template #body="slotProps">
                  <Button icon="pi pi-pencil"
                          class="p-button-rounded p-button-warning"
                          v-if="pedidoData && pedidoData.status === 'EL'"
                          @click="abrirPedItem(slotProps.data.id)" />
                </template>
              </Column>

<!--              <Column field="id" header="ID" :style="{width:'50px'}" ></Column>-->
              <Column field="produto" header="Código" :style="{width:'100px'}" class="text-left"></Column>

              <Column field="descproduto" header="Descrição" :style="{width:'300px'}" ></Column>

              <Column field="quant" header="Quantidade" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.quant ? formatDecimal(slotProps.data.quant) : 0.00}}
                </template>
              </Column>
              <Column field="vlrunit" header="Valor Unitário" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.vlrunliq ? formatCurrency(slotProps.data.vlrunliq) : 0.00}}
                </template>
              </Column>

              <Column field="vlripi" header="Valor IPI" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.vlripi ? formatCurrency(slotProps.data.vlripi) : 0.00}}
                </template>
              </Column>

              <Column field="vlrtotal" header="Valor Total" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.vlrtotal ? formatCurrency(slotProps.data.vlrtotal) : 0.00}}
                </template>
              </Column>

              <Column field="vlrtotal" header="Valor Líquido" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.vlrliqped ? formatCurrency(slotProps.data.vlrliqped) : 0.00}}
                </template>
              </Column>

              <Column field="desconto" header="Desconto (%)" class="text-right" :style="{width:'150px'}">
                <template #body="slotProps">
                  {{ slotProps.data.desconto ? formatDecimal(slotProps.data.desconto) : 0.00}}
                </template>
              </Column>



            </DataTable>

            </div>

          </div>
        </TabPanel>

        <TabPanel>

          <template #header>
            <span>Integração</span>
            <i class="pi pi-sitemap ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="pedidoLogData">

            <div class="field col-12 md:col-2">
              <label for="codexternoped">Código Protheus</label>
              <InputText v-model="pedidoData.codexterno" :disabled="true"></InputText>
            </div>

            <div class="field col-12 md:col-2">
              <label for="dtconfirmacao">Data Confirmação</label>
              <InputText v-model="pedidoLogData.created_at" :disabled="true"></InputText>
            </div>

            <div class="field col-12 md:col-2">
              <label for="percfrete">Porcentagem Frete</label>
              <InputNumber id="percfrete"
                           v-model="pedidoData.percfrete"
                           mode="decimal"
                           locale="pt-BR"
                           :disabled="true"
                           :minFractionDigits="2"/>
            </div>

            <div class="field col-12 md:col-2">
              <label for="volumetria">Volumetria</label>
              <InputNumber id="volumetria"
                           v-model="pedidoData.volumetria"
                           mode="decimal"
                           locale="pt-BR"
                           :disabled="true"
                           :minFractionDigits="2"/>
            </div>

            <div class="field col-12 md:col-2">
              <label for="cubagem">Cubagem</label>
              <InputNumber id="cubagem"
                           v-model="pedidoData.cubagem"
                           mode="decimal"
                           locale="pt-BR"
                           :disabled="true"
                           :minFractionDigits="2"/>
            </div>


<!--            <div class="field col-12 md:col-2">-->
<!--              <label for="numfatura">Nota Fiscal</label>-->
<!--              <InputText v-model="pedidoData.numfatura" :disabled="true"></InputText>-->
<!--            </div>-->


<!--            <div class="field col-12 md:col-2">-->
<!--              <label for="dtfatura">Data Fatura</label>-->
<!--              <Calendar :showIcon="true"-->
<!--                        :showButtonBar="true"-->
<!--                        v-model="pedidoData.dtfatura"-->
<!--                        dateFormat="dd/mm/yy"-->
<!--                        :disabled="true"></Calendar>-->
<!--            </div>-->



            <div class="field col-12 mt-4">
              <label for="obs">Objeto Enviado</label>
              <Textarea id="obs"
                        rows="4"
                        :disabled="true"
                        v-model="pedidoLogData.objenvio"/>
            </div>


            <div class="field col-12">
              <label for="obs">Objeto Recebido</label>
              <Textarea id="obs"
                        rows="4"
                        :disabled="true"
                        v-model="pedidoLogData.objretorno"/>
            </div>


          </div>

        </TabPanel>



          <TabPanel>
              <template #header>
                  <span>Notas Fiscais</span>
                  <i class="pi pi-list ml-2"></i>
              </template>

              <DataTable v-if="notasFiscData" :value="notasFiscData" :scrollable="true" v-model:expandedRows="expandNotaItems"
                         scrollHeight="400px" :loading="loadingNota" scrollDirection="both" class="mt-3">

                  <Column :expander="true" headerStyle="width: 3rem" />

                  <Column field="codexterno" header="Código" :style="{width:'100px'}" ></Column>


                  <Column header="Código" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{ slotProps.data.codexterno }}
                      </template>
                  </Column>

                  <Column header="Nota" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{ slotProps.data.nota }}
                      </template>
                  </Column>

                  <Column header="Série" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{slotProps.data.serie}}
                      </template>
                  </Column>


                  <Column field="downloadxml" header="Download XML" :style="{width:'200px'}">
                      <template #body="slotProps">
                          <Button icon="pi pi-file-excel" class="p-button-rounded p-button-help mr-2 mb-2" @click="base64ToXml(slotProps)" />
                      </template>
                  </Column>

                  <Column field="downloadpdf" header="Download PDF" :style="{width:'200px'}">
                      <template #body="slotProps">
                          <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-success mr-2 mb-2" @click="base64ToPdf(slotProps)" />
                      </template>
                  </Column>


                  <template #expansion="slotProps">
                      <div class="p-3">
                          <h4>Produtos NF {{slotProps.data.codexterno}}</h4>
                          <DataTable :value="slotProps.data.items" responsiveLayout="scroll">
                              <Column field="id" header="Id" :sortable="false" :style="{width:'50px'}" >
                                  <template #body="slotProps">
                                      {{slotProps.data.id}}
                                  </template>
                              </Column>

                              <Column field="produto" header="Produto" :sortable="false" :style="{width:'100px'}" >
                                  <template #body="slotProps">
                                      {{slotProps.data.produto}}
                                  </template>
                              </Column>

                              <Column field="descproduto" header="Descrição" :sortable="false" :style="{width:'500px'}" >
                                  <template #body="slotProps">
                                      {{slotProps.data.descproduto}}
                                  </template>
                              </Column>

                              <Column field="peso" header="Peso" :sortable="false" :style="{width:'150px'}" class="text-right">
                                  <template #body="slotProps" :sortable="false">
                                      {{formatDecimal(slotProps.data.peso)}}
                                  </template>
                              </Column>

                              <Column field="quant" header="Quantidade" :sortable="false" :style="{width:'150px'}" class="text-right">
                                  <template #body="slotProps" :sortable="false">
                                      {{formatDecimal(slotProps.data.quant)}}
                                  </template>
                              </Column>

                              <Column field="vlrunit" header="Unitário" :sortable="false" :style="{width:'150px'}" class="text-right">
                                  <template #body="slotProps" :sortable="false">
                                      {{formatDecimal(slotProps.data.vlrunit)}}
                                  </template>
                              </Column>

                              <Column field="vlrtotal" header="Total" :sortable="false" :style="{width:'150px'}" class="text-right" >
                                  <template #body="slotProps" :sortable="false">
                                      {{formatDecimal(slotProps.data.vlrtotal)}}
                                  </template>
                              </Column>


                          </DataTable>
                      </div>
                  </template>

              </DataTable>

          </TabPanel>







        <TabPanel>
          <template #header>
            <span>Faturamento</span>
            <i class="pi pi-list ml-2"></i>
          </template>

          <DataTable v-if="notasData" :value="notasData" :scrollable="true" v-model:expandedRows="expandNotaItems"
                     scrollHeight="400px" :loading="loadingData" scrollDirection="both" class="mt-3">

            <Column :expander="true" headerStyle="width: 3rem" />

            <Column field="codexterno" header="Código" :style="{width:'100px'}" ></Column>


            <Column header="Emissão" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.emissao)}}
              </template>
            </Column>

            <Column header="Saída" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.saida)}}
              </template>
            </Column>

            <Column header="Condição" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.condpgto}}
              </template>
            </Column>

            <Column header="CFOP" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.natop}}
              </template>
            </Column>

            <Column header="Transportadora" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.transp}}
              </template>
            </Column>


            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'pedido-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>

            <Column field="peso" header="Peso" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.peso)}}</span>
              </template>
            </Column>

            <Column field="valor" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.valor)}}</span>
              </template>
            </Column>


            <template #expansion="slotProps">
              <div class="p-3">
                <h4>Produtos NF {{slotProps.data.codexterno}}</h4>
                <DataTable :value="slotProps.data.items" responsiveLayout="scroll">
                  <Column field="id" header="Id" :sortable="false" :style="{width:'50px'}" >
                    <template #body="slotProps">
                      {{slotProps.data.id}}
                    </template>
                  </Column>

                  <Column field="produto" header="Produto" :sortable="false" :style="{width:'100px'}" >
                    <template #body="slotProps">
                      {{slotProps.data.produto}}
                    </template>
                  </Column>

                  <Column field="descproduto" header="Descrição" :sortable="false" :style="{width:'500px'}" >
                    <template #body="slotProps">
                      {{slotProps.data.descproduto}}
                    </template>
                  </Column>

                  <Column field="peso" header="Peso" :sortable="false" :style="{width:'150px'}" class="text-right">
                    <template #body="slotProps" :sortable="false">
                      {{formatDecimal(slotProps.data.peso)}}
                    </template>
                  </Column>

                  <Column field="quant" header="Quantidade" :sortable="false" :style="{width:'150px'}" class="text-right">
                    <template #body="slotProps" :sortable="false">
                      {{formatDecimal(slotProps.data.quant)}}
                    </template>
                  </Column>

                  <Column field="vlrunit" header="Unitário" :sortable="false" :style="{width:'150px'}" class="text-right">
                    <template #body="slotProps" :sortable="false">
                      {{formatDecimal(slotProps.data.vlrunit)}}
                    </template>
                  </Column>

                  <Column field="vlrtotal" header="Total" :sortable="false" :style="{width:'150px'}" class="text-right" >
                    <template #body="slotProps" :sortable="false">
                      {{formatDecimal(slotProps.data.vlrtotal)}}
                    </template>
                  </Column>


                </DataTable>
              </div>
            </template>

          </DataTable>

        </TabPanel>





        <TabPanel>
          <template #header>
            <span>Financeiro</span>
            <i class="pi pi-money-bill ml-2"></i>
          </template>

          <DataTable v-if="financeirosData" :value="financeirosData" :scrollable="true" scrollHeight="400px" :loading="loadingData" scrollDirection="both" class="mt-3">
            <Column header="Código" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.codexterno}}
              </template>
            </Column>

              <Column header="Código" :style="{width:'200px'}">
                  <template #body="slotProps">
                      <Button :loading='loadingBoleto' v-tooltip='"Download Boletos"' icon="pi pi-file-pdf" class="p-button-rounded p-button-success mr-2 mb-2"  @click='downloadBoleto(slotProps.data.codexterno)'/>
                  </template>
              </Column>

            <Column header="Pedido" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.pedido}}
              </template>
            </Column>
            <Column header="Número" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.numero}}
              </template>
            </Column>

            <Column header="Emissão" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.emissao)}}
              </template>
            </Column>

            <Column header="Vencimento" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.vencimento)}}
              </template>
            </Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'financeiro-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{ nomeStatusFinan(data.status) }}</span>
              </template>
            </Column>
            <Column field="valor" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.valor)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


          <TabPanel>
              <template #header>
                  <span>Boletos</span>
                  <i class="pi pi-list ml-2"></i>
              </template>
              <DataTable v-if="boletosData" :value="boletosData" :scrollable="true"
                         scrollHeight="400px" :loading="loadingBoleto" scrollDirection="both" class="mt-3">
                  <Column :expander="true" headerStyle="width: 3rem" />
                  <Column field="codexterno" header="Código" :style="{width:'100px'}" ></Column>
                  <Column header="Código" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{ slotProps.data.codexterno }}
                      </template>
                  </Column>
                  <Column header="Nota" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{ slotProps.data.nota }}
                      </template>
                  </Column>
                  <Column header="Série" :style="{width:'200px'}">
                      <template #body="slotProps">
                          {{slotProps.data.serie}}
                      </template>
                  </Column>
                  <Column field="downloadxml" header="Download XML" :style="{width:'200px'}">
                      <template #body="slotProps">
                          <Button icon="pi pi-file-excel" class="p-button-rounded p-button-help mr-2 mb-2" @click="base64ToXml(slotProps)" />
                      </template>
                  </Column>
                  <Column field="downloadpdf" header="Download PDF" :style="{width:'200px'}">
                      <template #body="slotProps">
                          <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-success mr-2 mb-2" @click="base64ToPdf(slotProps)" />
                      </template>
                  </Column>
              </DataTable>
          </TabPanel>


        <TabPanel>

          <template #header>
            <span>Anexos</span>
            <i class="pi pi-paperclip ml-2"></i>
          </template>

          <div class="p-fluid formgrid grid" v-if="pedidocadData">



            <Divider layout="horizontal">
              <b>Anexos</b>
              <i class="pi pi-file ml-2"></i>
            </Divider>


            <div class="field col-12 md:col-3">
              <h6>PDF Contrato Social</h6>
              <FileUpload @click="arqSubindo = 'conta_energia'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqContaEnergia" @click="excluirFile(arqContaEnergia)"  style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqContaEnergia" @click="downloadFile(arqContaEnergia.url)"  style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Foto da Fachada</h6>
              <FileUpload @click="arqSubindo = 'relogio'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqRelogio" @click="excluirFile(arqRelogio)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqRelogio" @click="downloadFile(arqRelogio.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Foto do Interior</h6>
              <FileUpload @click="arqSubindo = 'quadro_distribuicao'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqQuadroDistribuicao" @click="excluirFile(arqQuadroDistribuicao)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqQuadroDistribuicao" @click="downloadFile(arqQuadroDistribuicao.url)" style="width: 50%;"/>
            </div>

            <div class="field col-12 md:col-3">
              <h6>Foto Exposição Produtos</h6>
              <FileUpload @click="arqSubindo = 'disjuntor_geral'" :multiple="false" @before-send="beforeUpload" :url="baseUrl" choose-label="Escolher arquivo" upload-label="Upload Servidor" cancel-label="Cancelar" mode="basic" name="arquivo" accept="image/*, application/pdf" :maxFileSize="1000000" @upload="onUpload" style="width: 100%" />
              <Button label="Excluir" icon="pi pi-trash" class="p-button-secondary p-button-outlined" v-if="arqDisjuntorGeral" @click="excluirFile(arqDisjuntorGeral)" style="width: 50%;"/>
              <Button label="Download" icon="pi pi-download" class="p-button-secondary" v-if="arqDisjuntorGeral" @click="downloadFile(arqDisjuntorGeral.url)" style="width: 50%;"/>
            </div>


          </div>

        </TabPanel>



        <TabPanel>

          <template #header>
            <span>Ocorrências</span>
            <i class="pi pi-exclamation-circle ml-2"></i>
          </template>

          <DataTable v-if="ocorsData" :value="ocorsData" :scrollable="true" scrollHeight="400px" :loading="loadingOcors" scrollDirection="both" class="mt-3">

            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.created_at)}}
              </template>
            </Column>
<!--            <Column field="tipo" header="Tipo" :style="{width:'200px'}"></Column>-->

            <Column field="tipo" header="Tipo" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge tipo-' + (data.tipo ? data.tipo.toLowerCase() : '')">{{data.tipo}}</span>
              </template>
            </Column>

            <Column field="obs" header="Observação" :style="{width:'300px'}" ></Column>

            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'cliocor-badge status-' + (data.status ? data.status.toLowerCase() : '')"> {{ data.status }} </span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>


          </DataTable>


        </TabPanel>


        <TabPanel>

          <template #header>
            <span>Leads</span>
            <i class="pi pi-id-card ml-2"></i>
          </template>

          <DataTable v-if="leadsData" :value="leadsData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/leads')" />
              </template>
            </Column>
            <Column field="code" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column field="representante" header="Representante" :style="{width:'200px'}"></Column>
            <Column field="inventoryStatus" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'lead-badge status-' + (data.inventoryStatus ? data.inventoryStatus.toLowerCase() : '')">{{data.inventoryStatus}}</span>
              </template>
            </Column>
            <Column field="rating" header="Visualizações" :sortable="true">
              <template #body="slotProps">
                <span class="p-column-title">Rating</span>
                <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" />
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.price)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>


        </TabPanel>

        <TabPanel>
          <template #header>
            <span>Orçamentos</span>
            <i class="pi pi-eye ml-2"></i>
          </template>

          <DataTable v-if="orcamentosData" :value="orcamentosData" :scrollable="true" scrollHeight="400px" :loading="loadingLeads" scrollDirection="both" class="mt-3">
            <Column header="Ações" class="text-right" :style="{width:'100px'}" >
              <template #body="">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" @click="$router.push('/orcamentodetalhe')" />
              </template>
            </Column>
            <Column field="id" header="Código" :style="{width:'100px'}" ></Column>
            <Column header="Data" :style="{width:'200px'}">
              <template #body="slotProps">
                {{formatDate(slotProps.data.date)}}
              </template>
            </Column>
            <Column header="Representante" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.representative.name}}
              </template>
            </Column>
            <Column header="Cidade" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.cidade}}
              </template>
            </Column>
            <Column header="Estado" :style="{width:'200px'}">
              <template #body="slotProps">
                {{slotProps.data.estado}}
              </template>
            </Column>
            <Column field="status" header="Status" :style="{width:'200px'}">
              <template #body="{data}">
                <span :class="'orcamento-badge status-' + (data.status ? data.status.toLowerCase() : '')">{{data.status}}</span>
              </template>
            </Column>
            <Column field="price" header="Valor" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatCurrency(data.balance)}}</span>
              </template>
            </Column>
            <Column field="potencia" header="Potência" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span class="text-bold">{{formatDecimal(data.potencia)}}</span>
              </template>
            </Column>
          </DataTable>

        </TabPanel>


      </TabView>
    </div>




    <Sidebar v-model:visible="sidebarPedItem" :baseZIndex="1000" position="right" class="p-sidebar-md">
      <h5 v-if="!pedItemData.id">Novo Produto</h5>
      <h5 v-if="pedItemData.id">Editando Item Pedido {{ pedItemData.id }}</h5>

      <div class="grid p-fluid mt-3" >

        <div class="field col-12 md:col-12">
          <label for="cliente">Produto</label>
          <AutoComplete
              placeholder="Pesquisar produto (mínimo 5 caracteres)"
              :class="validPedItem && !pedItemData.produto ? 'p-invalid' : ''"
              :dropdown="true"
              :multiple="false"
              v-model="selectedAutoValuePedItem"
              :suggestions="autoFilteredPedItem"
              @complete="searchPedItem($event)"
              field="descricao"
              @item-select="selecionaPedItem($event)">
            <template #item="slotProps" style="width: 20%">
              <div class="p-fluid formgrid grid">

                  <img :alt="slotProps.item.codexterno" :src="'layout/images/produto-pesquisa-2.png'" style="width: 35px;"/>

                  <!--                <img :alt="slotProps.item.codexterno" :src="'https://mobagile.com/fotos_nesher/peq/7899766207449.png'" style="width: 105px;"/>-->
                  <!--                <img :alt="slotProps.item.codexterno" :src="'https://mobagile.com/fotos_nesher/peq/' + slotProps.item.codexterno + '.png'" style="width: 105px;"/>-->

                <div class="ml-1" style="width: 15%">{{slotProps.item.codexterno}} <p>{{ formatCurrency(slotProps.item.saldo) }}</p></div>
                <div style="width: 60%">{{slotProps.item.descricao}} <p><b>R$ {{ formatCurrency(slotProps.item.preco) }}</b></p></div>

              </div>
            </template>
          </AutoComplete>
          <small class="p-invalid" v-if="validPedItem && !pedItemData.produto">Campo obrigatório.</small>
        </div>


        <div class="field col-12 md:col-8" v-if="pedItemData.produto && (pedidoData.tipo === '29'
        || pedidoData.tipo === '31'
        || pedidoData.tipo === '58'
        || pedidoData.tipo === '59')">
          <label for="frete">Motivo da Assistência</label>
          <Dropdown
              id="frete"
              v-model="pedItemData.mtvassist"
              :options="mtvAssistItens"
              optionLabel="name"
              optionValue="code"
              :class="validPedItem && !pedItemData.mtvassist ? 'p-invalid' : ''"
              placeholder="Selecione o motivo para a assistência"  >
          </Dropdown>
          <small class="p-invalid" v-if="validPedItem && !pedItemData.mtvassist">Campo obrigatório.</small>
        </div>


        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="peso">Peso</label>
          <InputNumber id="peso"
                       v-model="pedItemData.peso"
                       mode="decimal"
                       :readonly="true"
                       locale="pt-BR"
                       :minFractionDigits="2"/>
        </div>



        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="tabpreco">Tabela</label>
          <InputText id="tabprecoitem"
                       v-model="pedItemData.tabpreco"
                       mode="decimal"
                       :readonly="true"
                       locale="pt-BR"/>
        </div>

        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="vlrtab">Valor Tabela</label>
          <InputNumber id="vlrtab"
                     v-model="pedItemData.vlrtab"
                     mode="decimal"
                     :readonly="true"
                     :minFractionDigits="2"
                     locale="pt-BR"/>
        </div>


          <div class="field col-12 md:col-3" v-if="pedItemData.produto">
              <label for="vlrunit">Valor Unit. Brt.</label>
              <InputNumber id="vlrunit"
                           v-model="pedItemData.vlrunit"
                           mode="currency"
                           currency="BRL"
                           :readonly="true"
                           :class="validPedItem && !pedItemData.vlrunit ? 'p-invalid' : ''"
                           locale="pt-BR"/>
              <small class="p-invalid" v-if="validPedItem && !pedItemData.vlrunit">Campo obrigatório, maior que zero.</small>
          </div>

        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="vlrbruto">Total Bruto</label>
          <InputNumber id="vlrtab"
                     v-model="pedItemData.vlrbruto"
                     mode="decimal"
                     :readonly="true"
                     :minFractionDigits="2"
                     locale="pt-BR"/>
        </div>


          <div class="field col-12 md:col-3" v-if="pedItemData.produto">
              <label for="vlrunliq">Valor Unitário</label>
              <InputNumber id="vlrunliq"
                           v-model="pedItemData.vlrunliq"
                           mode="currency"
                           currency="BRL"
                           :readonly="true"
                           locale="pt-BR"/>
          </div>


        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="vlrtotal">Valor Total</label>
          <InputNumber id="vlrtotal"
                       v-model="pedItemData.vlrtotal"
                       mode="currency" currency="BRL"
                       locale="pt-BR"
                       :class="validPedItem && !pedItemData.vlrtotal ? 'p-invalid' : ''"
                       :readonly="true"/>
          <small class="p-invalid" v-if="validPedItem && !pedItemData.vlrtotal">Campo obrigatório, maior que zero.</small>
        </div>


        <div class="field col-12 md:col-3" v-if="pedItemData.produto">
          <label for="vlrtotal">Valor Líquido</label>
          <InputNumber id="vlrtotal"
                       v-model="pedItemData.vlrtotalliq"
                       mode="currency" currency="BRL"
                       locale="pt-BR"
                       :readonly="true"/>
        </div>

        <div class="field col-12 md:col-6" v-if="pedItemData.produto">
          <label for="desconto">Desconto (%) | Comissão ({{ percComissaoItem != null ? formatDecimal(percComissaoItem) : '0,00' }} %)</label>
          <InputNumber id="quant"
                       v-model="pedItemData.desconto"
                       mode="decimal"
                       locale="pt-BR"
                       :min="0"
                       :max="11.5"
                       :minFractionDigits="2"/>
          <Slider v-model="pedItemData.desconto" :step="0.1" :min="0" :max="11.50" />
        </div>

        <div class="field col-12 md:col-6" v-if="pedItemData.produto">
          <label for="quant">Quantidade</label>
          <InputNumber id="quant"
                       v-model="pedItemData.quant"
                       mode="decimal"
                       locale="pt-BR"
                       :class="validPedItem && !pedItemData.quant ? 'p-invalid' : ''"
                       :minFractionDigits="2"/>
          <small class="p-invalid" v-if="validPedItem && !pedItemData.quant">Campo obrigatório, maior que zero.</small>
        </div>


<!--        <div class="col-12 md:col-12"  v-if="pedItemData.produto">-->
<!--          <label for="obsocor">Observações</label>-->
<!--          <div class="p-inputgroup">-->
<!--            <Textarea id="obsitem"-->
<!--                      rows="2"-->
<!--                      v-model="pedItemData.obs" />-->
<!--          </div>-->
<!--        </div>-->


          <div class="col-6 md:col-6"  v-if="pedItemData.produto">
            <Button label="Cancelar" icon="pi pi-ban" class="mr-2 p-button-outlined" @click="sidebarPedItem = false; validPedItem = false;"  />
          </div>

          <div class="col-6 md:col-6"  v-if="pedItemData.produto">
            <Button label="Salvar Item no Pedido" icon="pi pi-save" class="mr-2 p-button-outlined" @click="savePedItem()" :loading="loadingBtnSaveItem" />
          </div>

        <div class="col-6 md:col-6" v-if="pedItemData.id">
          <Button label="Excluir" icon="pi pi-trash" class="mr-2 p-button-danger p-button-outlined" @click="excluirOcor()"  />
        </div>


      </div>


<!--      <div class="col-12" v-if="pedItemData && pedItemData.id > 0">-->
<!--        <div class="card widget-boxes  p-0 grid grid-nogutter">-->

<!--          <div class="col-12 md:col-6 lg:col-6 py-3 px-3 border-none md:border-right-1 border-solid">-->
<!--            <div >-->
<!--              <Avatar icon="pi pi-shopping-cart" size="large" shape="circle" class="text-base font-bold"-->
<!--                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>-->
<!--              <span class="text-xl ml-2">Compra Anterior</span>-->
<!--            </div>-->
<!--            <span class="block font-bold text-3xl mb-3">0</span>-->
<!--            <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>-->
<!--          </div>-->

<!--          <div class="col-12 md:col-6 lg:col-6 py-3 px-3 border-none md:border-right-1 border-solid">-->
<!--            <div >-->
<!--              <Avatar icon="pi pi-comments" size="large" shape="circle" class="text-base font-bold"-->
<!--                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>-->
<!--              <span class="text-xl ml-2">Preço</span>-->
<!--            </div>-->
<!--            <span class="block font-bold text-3xl mb-3">R$ 0,00</span>-->
<!--            <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->



    </Sidebar>


    <ConfirmDialog group="dialog" />



  </div>
</template>
<script>

import PedidoService from "@/service/PedidoService";
import api from "@/axios";
import moment from 'moment'


export default {
  components: {

  },
  data() {
    return {
      notasFiscData: null,
      boletosData: null,
      loadingXML: null,
      loadingNota: null,
      loadingBoleto: null,
      percComissaoItem: 0,
      userData: null,
      loadingCopy: null,
      expandNotaItems: [],
      notasData: null,
      notasItemsData: null,
      financeirosData: null,
      pedidoLogData: {
        'objenvio': '',
        'objretorno': '',
      },
      validConfPedido: false,
      percDescPraticado: 0.00,
      percDescItens: 0.00,
      percComissao: 0,
      valorComissao: 0,
      faixaComissao: '',
      valorLiqPed: 0,
      valorBrtPed: 0,
      valorDescPedTipo: 0,
      valorDescCond: 0,
      valorDescManga: 0,

      condpgtoData: {},
      formapgtoData: null,
      pedtipoData: {
        'codtab': '037',
        'tipo': 'S'
      },

      validPedido: false,
      loadingPDF: false,
      pedItensData: {},
      transpData: null,
      transpRedData: null,
      tabprecoData: null,
      represData: null,

      selectedAutoValueRepres: null,
      autoFilteredRepres: null,
      autoValueRepres: [],

      selectedAutoValueCodTab: null,
      autoFilteredCodTab: null,
      autoValueCodTab: [],

      selectedAutoValueTransp: null,
      autoFilteredTransp: null,
      autoValueTransp: [],

      selectedAutoValueTranspRedesp: null,
      autoFilteredTranspRedesp: null,
      autoValueTranspRedesp: [],

      selectedAutoValuePedItem: null,
      autoFilteredPedItem: null,
      autoValuePedItem: [],

      selectedAutoValuePedTipo: null,
      autoFilteredPedTipo: null,
      autoValuePedTipo: [],

      selectedAutoValueForma: null,
      autoFilteredForma: null,
      autoValueForma: [],

      selectedAutoValueCondicao: null,
      autoFilteredCondicao: null,
      autoValueCondicao: [],

      selectedAutoValueCliente: null,
      autoFilteredCliente: [],
      autoValueCliente: [],

      activeIndex: 0,
      loadingBtnSaveItem: false,
      validPedItem: false,
      sidebarPedItem: false,
      pedItemData: {},
      produtoData: {},

      arqSubindo: '',
      arqFaixada: null,
      arqTelhado: null,
      arqDisjuntorGeral: null,
      arqQuadroDistribuicao: null,
      arqRelogio: null,
      arqContaEnergia: null,
      arquivos: [],
      tipoUpload: '',
      baseUrl: '',

      tpfobItems: [
        {code: '1', name: 'Envia'},
        {code: '2', name: 'Retira'},
      ],

      freteItems: [
        {code: 'C', name: 'CIF'},
        {code: 'F', name: 'FOB'},
        {code: 'T', name: 'Por conta terceiros'},
        {code: 'R', name: 'Por conta remetente'},
        {code: 'D', name: 'Por conta destinatário'},
        {code: 'S', name: 'Sem frete'},
      ],

      mtvAssistItens: [
        {code: '000008', name: 'ACESSORIO COM DEFEITO'},
        {code: '000006', name: 'ACESSORIO FALTANTE'},
        {code: '000011', name: 'COR FORA DO PADRAO'},
        {code: '000012', name: 'COR TROCADA'},
        {code: '000013', name: 'DESPLACAMENTO'},
        {code: '000007', name: 'FERRAGEM FALTANTE'},
        {code: '000009', name: 'FURACAO/ENCAIXE INCORRETO'},
        {code: '000004', name: 'PECA DANIFICADA NA ENTREGA'},
        {code: '000003', name: 'PECA DANIFICADA NO TRANSPORTE'},
        {code: '000001', name: 'PECA FALTANTE'},
        {code: '000002', name: 'PECA QUEBRADA'},
        {code: '000010', name: 'PECA REPETIDA'},
        {code: '000005', name: 'PECA RISCADA / ARRANHADA'},
      ],

      tipoocorItems: [
        {code: 'AV', name: 'Aviso'},
        {code: 'CO', name: 'Confirmação'},
        {code: 'PR', name: 'Problema'},
      ],

      telhorieItems: [
        {code: 'NO', name: 'Norte'},
        {code: 'SU', name: 'Sul'},
        {code: 'LE', name: 'Leste'},
        {code: 'OE', name: 'Oeste'},
        {code: 'ND', name: 'Nordeste'},
        {code: 'SD', name: 'Sudoeste'},
        {code: 'NR', name: 'Noroeste'},

      ],
      geradorItems: [
        {code: 'SIM', name: 'Sim'},
        {code: 'NAO', name: 'Não'},

      ],
      telhestrItems: [
        {code: 'MAD', name: 'Madeira'},
        {code: 'MET', name: 'Metal'},
        {code: 'TER', name: 'Terça'},
        {code: 'OUT', name: 'Outro'},
      ],
      telhadoItems: [
        {code: 'FIB', name: 'Fibrocimento'},
        {code: 'COL', name: 'Colonial'},
        {code: 'ZIN', name: 'Zinco'},
        {code: 'OUT', name: 'Outro'},
      ],
      tensaoItems: [
        {code: '127', name: '127V'},
        {code: '220', name: '220V'},
        {code: '380', name: '380V'},
      ],
      sistemaItems: [
        {code: 'MN', name: 'Monofásico'},
        {code: 'BI', name: 'Bifásico'},
        {code: 'TR', name: 'Trifásico'},
      ],
      radioValueEspaco: null,
      radioValueAumento: null,
      radioValueInternet: null,
      radioValueGrupo: null,
      pedidocadData: {
        'perfil': 'coisa'
      },
      selectedAutoValueCidade: null,
      autoFilteredCidade: [],
      autoValueCidade: [],
      loadingCEP: false,
      loadingData: true,
      loadingBtnSave: false,
      loadingBtn: [false, false, false],
      vendasData: null,
      orcamentosData: null,
      obrasData: null,
      projetosData: null,

      loadingOcors: null,
      ocorsData: null,

      loadingLeads: true,
      leadService: null,
      leadsData: null,
      pedidoData: {
        'id': 0,
        'tipo': '01',
        'codtab': '037',
        'dtemissao': new Date(),
        'status': 'EL',
        'desconto': 0,
        'frete': 'C'
      },
      clienteData: {

      },
      localData: null,
      tipoItems: [
        {code: 'PF', name: 'Pessoa Física'},
        {code: 'PJ', name: 'Pessoa Jurídica'},
      ],

      estadoItems: [
        {code: 'AC', name: 'Acre'},
        {code: 'AL', name: 'Alagoas'},
        {code: 'AP', name: 'Amapá'},
        {code: 'AM', name: 'Amazonas'},
        {code: 'BA', name: 'Bahia'},
        {code: 'CE', name: 'Ceará'},
        {code: 'DF', name: 'Distrito Federal'},
        {code: 'ES', name: 'Espírito Santo'},
        {code: 'GO', name: 'Goiás'},
        {code: 'MA', name: 'Maranhão'},
        {code: 'MT', name: 'Mato Grosso'},
        {code: 'MS', name: 'Mato Grosso do Sul'},
        {code: 'MG', name: 'Minas Gerais'},
        {code: 'PA', name: 'Pará'},
        {code: 'PB', name: 'Paraíba'},
        {code: 'PR', name: 'Paraná'},
        {code: 'PE', name: 'Pernambuco'},
        {code: 'PI', name: 'Piauí'},
        {code: 'RJ', name: 'Rio de Janeiro'},
        {code: 'RN', name: 'Rio Grande do Norte'},
        {code: 'RS', name: 'Rio Grande do Sul'},
        {code: 'RO', name: 'Rondônia'},
        {code: 'RR', name: 'Roraima'},
        {code: 'SC', name: 'Santa Catarina'},
        {code: 'SP', name: 'São Paulo'},
        {code: 'SE', name: 'Sergipe'},
        {code: 'TO', name: 'Tocantins'},
      ],
      dropdownItem: null,
    }
  },

  created() {
    this.pedidoService = new PedidoService();

  },


  mounted() {

    console.log('mounted peddetalhe')

    //console.log(moment.now())
    console.log(api.getUri())
    this.baseUrl = api.getUri() + 'api/ssuploadfile'


    let idpedido = this.$route.params.id
    console.log('idpedido')
    console.log(idpedido)
    if (idpedido > 0) {
      this.pedidoService.getPedido(idpedido).then((data) => {
        console.log('getPedido')
        console.log(data[0])

        if (data === 'nao_permitido') {
          this.$router.replace({ path: '/acessorestrito' })
        }else{

          this.userData = data[0]
          this.pedidoData = data[1]
          this.clienteData = data[2]
          this.pedItensData = data[3]
          this.condpgtoData = data[4]
          this.formapgtoData = data[5]
          this.pedtipoData = data[6]
          this.transpData = data[7]
          this.tabprecoData = data[8]
          this.represData = data[9]
          this.financeirosData = data[10]
          this.notasData = data[11]
          if (data[12]) {
            this.pedidoLogData = data[12]

            // this.pedidoLogData.created_at = moment(this.pedidoLogData.created_at).toDate()
            // let dtCria = moment(this.pedidoLogData.created_at).format("YYYY-MM-DD HH:mm:ss")
            // console.log('dtCria')
            // console.log(dtCria.format("YYYY-MM-DD HH:mm:ss"))

            this.pedidoLogData.created_at = moment(this.pedidoLogData.created_at).format("DD/MM/YYYY HH:mm:ss")

            console.log('this.pedidoLogData.created_at')
            console.log(this.pedidoLogData.created_at)
            //console.log(moment(this.pedidoLogData.created_at).toDate())
          }
          this.transpRedData = data[13]

          if (this.financeirosData){
            this.financeirosData.forEach(financ => {
              financ.emissao = moment(financ.emissao).toDate()
              financ.vencimento = moment(financ.vencimento).toDate()
              financ.created_at = moment(financ.created_at).toDate()
            });
          }

          console.log('this.notasData')
          console.log(this.notasData)

          if (this.notasData){
            this.notasData.forEach(nota => {
              nota.emissao = moment(nota.emissao).toDate()
              nota.saida = moment(nota.saida).toDate()
              nota.created_at = moment(nota.created_at).toDate()
            });
          }

          this.pedItemData.pedido_id = this.pedidoData.id

          this.selectedAutoValueCliente = this.clienteData ? this.clienteData.nome : null
          this.selectedAutoValueCondicao = this.condpgtoData ? this.condpgtoData.descricao : null
          this.selectedAutoValueForma = this.formapgtoData ? this.formapgtoData.descricao : null
          this.selectedAutoValuePedTipo = this.pedtipoData ? this.pedtipoData.descricao : null
          this.selectedAutoValueTransp = this.transpData ? this.transpData.descricao : null
          this.selectedAutoValueTranspRedesp = this.transpRedData ? this.transpRedData.descricao : null
          this.selectedAutoValueCodTab = this.tabprecoData ? this.tabprecoData.descricao : null
          this.selectedAutoValueRepres = this.represData ? this.represData.descricao : null

          if (this.pedidoData.dtemissao)
            this.pedidoData.dtemissao = moment(this.pedidoData.dtemissao).toDate()
          if (this.pedidoData.dtentrega)
            this.pedidoData.dtentrega = moment(this.pedidoData.dtentrega).toDate()
          if (this.pedidoData.dtfatura)
            this.pedidoData.dtfatura = moment(this.pedidoData.dtfatura).toDate()

          this.calculaTotalPedido()
          this.loadingData = false

        }
      })

      // this.getOcorrencias(idpedido)
      // this.pedidoService.getLeads().then((data) => {
      //   console.log('pedidoDetalhe_0')
      //   this.leadsData = data
      //   this.loadingLeads = false
      //   // console.log(this.leadsData)
      //   this.leadsData.forEach(lead => lead.date = new Date(lead.date));
      // });
      //
      // this.pedidoService.getOrcamentos().then((data) => {
      //   this.orcamentosData = data
      //   // console.log(this.orcamentosData)
      //   this.orcamentosData.splice(0,6)
      //   // console.log(this.orcamentosData)
      //   this.orcamentosData.forEach(orcto => orcto.date = new Date(orcto.date));
      // });
    } else{

      this.pedidoService.getPedidoNovo().then((dataPedNovo) => {
        console.log('getPedidoNovo')
        console.log(dataPedNovo[2])

        this.userData = dataPedNovo[0]
        let representante = dataPedNovo[1]

        this.pedidoData['repres'] = representante.codexterno
        this.selectedAutoValueRepres = representante.descricao



        this.selectedAutoValuePedTipo = 'Venda de Mercadoria'

        if (dataPedNovo[2]) {
          this.selectedAutoValueCodTab = dataPedNovo[2].descricao
          this.pedtipoData.codtab = dataPedNovo[2].codexterno
          this.pedidoData.codtab = dataPedNovo[2].codexterno


        } else {
          this.selectedAutoValueCodTab = 'TABELA 037 VENDA'
          this.pedtipoData.codtab =  '037'
          this.pedidoData.codtab =  '037'

        }

        if (this.userData.tipo === 'repre'){
          console.log('repressss')
          this.pedidoData.repres = this.userData.codrepres
        }

        this.loadingData = false

      })

    }
  },

  watch: {
    'pedidoData.desconto': {
      handler(){
        this.calculaTotalPedido()
      }
    },

    'pedidoData.descond': {
      handler(){
        this.calculaTotalPedido()
      }
    },

    'pedItemData.quant': {
      handler(){
        this.calculaTotalItem()
      }
    },
    'pedItemData.vlrunit': {
      handler(){
        this.calculaTotalItem()
      }
    },
    'pedItemData.desconto': {
      handler(){
        this.calculaTotalItem()
      }
    },
  },

  methods: {
      alterouTab(event){
          console.log('entrouTabFinanc')
          console.log(event.index)
          if (event.index === 2) {
              this.downloadNotaExml()
          }
          //
          //     this.clienteService.getssclifinancs(
          //         this.clienteData.codexterno
          //     ).then((data) => {
          //         console.log('this.financsData')
          //         if (data){
          //             console.log(data)
          //         }else{
          //             console.log('nao data')
          //         }
          //
          //
          //         this.financeirosData = data
          //         this.loadingFinanceiro = false
          //         //
          //         this.financeirosData.forEach(financ => {
          //             financ.emissao = moment(financ.emissao).toDate()
          //             financ.vencimento = moment(financ.vencimento).toDate()
          //             financ.created_at = moment(financ.created_at).toDate()
          //         });
          //
          //
          //     });
          // }
      },

      base64ToPdf(env){
          console.log('base64ToPdf')
          console.log(env.data.danfe)
          var b64 = env.data.danfe
          var link = document.createElement('a')
          link.innerHTML = 'Download PDF Nota Fiscal'
          link.download = `nf_Nesher_${env.data.codexterno}.pdf`
          link.href = 'data:application/octet-stream;base64,' + b64;
          link.click()
      },

      base64ToPdfBoleto(env, cod){
          console.log('base64ToPdfBoleto')
          // console.log(env)
          var b64 = env
          var link = document.createElement('a')
          link.innerHTML = 'Download PDF Nota Fiscal'
          link.download = `boleto_nesher_${cod}.pdf`
          link.href = 'data:application/octet-stream;base64,' + b64;
          link.click()
      },

      base64ToXml(env){
          console.log('base64ToXml')
          console.log(env.data.xml)
          var b64 = env.data.xml
          var link = document.createElement('a')
          link.innerHTML = 'Download XML Nota Fiscal'
          link.download = `nf_Nesher_${env.data.codexterno}.xml`
          link.href = 'data:application/octet-stream;base64,' + b64;
          link.click()
      },

      downloadXML(){
          console.log('downloadXML')
          this.loadingXML = true
          this.pedidoService.downloadXML({
              'pedido': this.pedidoData.id
          }).then((pedData) => {
              console.log('resultado downloadXML')
              console.log(pedData)
              this.loadingXML = false
          })
      },

      downloadNotaExml(){
          console.log('downloadNota')
          this.loadingNota = true
          this.activeIndex = 2
          this.pedidoService.downloadNota({
              'pedido': this.pedidoData.id
          }).then((response) => {
              console.log('downloadPdfNota response');
              console.log(response)
              this.notasFiscData = response
              this.loadingNota = false
          });
      },

      downloadBoleto(codextFinan){
          console.log('downloadBoleto')
          this.loadingBoleto = true
          this.pedidoService.downloadBoleto({
              'pedido': codextFinan
          }).then((response) => {
              console.log('downloadPdfBoleto response');
              // console.log(response)
              this.base64ToPdfBoleto(response, codextFinan)
              this.loadingBoleto = false
          });
      },

    copiarPedido(){
      this.loadingCopy = true
      console.log('copiarPedido')
      this.pedidoService.copiarPed({
        'pedido': this.pedidoData.id
      }).then((pedData) => {
        console.log('resultado copiarped')
        console.log(pedData)
        this.pedidoData = pedData[0]
        this.pedItensData = pedData[1]
        this.pedidoLogData = {
          'objenvio' : '',
          'objretorno' : '',
        }
        this.loadingCopy = false

        this.$router.replace({ path: '/pedidodetalhe/' + this.pedidoData.id }).then(() => {
          this.$toast.add({severity:'success', summary: 'Pedido copiado', detail:'O pedido foi copiado com sucesso, você esta trabalhando com a nova cópia, favor revisar os itens do pedido', life: 5000});
        })

      })
    },

    nomeStatusFinan(sigla){
      switch (sigla) {
        case 'A':
          return 'Em Aberto'
        case 'B':
          return 'Baixado'
        default:
          return 'Não Definido'
      }
    },

    exportPedidoWS(){
      console.log('exportPedidoWS')
      this.loadingData = true
      this.pedidoService.confirmarPed({
        'pedido': this.pedidoData,
        'reenvio': true
      }).then((pedData) => {
        console.log('resultado confped')
        console.log(pedData)
        if (pedData[0] === 'Erro WS Protheus'){
          // this.$toast.add({severity:'warn', summary: 'Problemas com WS Protheus', detail: 'Web Service Protheus não respondendo, favor entrar em contato com suporte', life: 5000});
            this.$toast.add({severity:'warn', summary: 'Problemas com WS Protheus', detail: pedData[1], life: 5000});
          this.pedidoLogData = pedData[1]
          this.pedidoData.status = 'CO'
          this.pedidoData.stserp = 'ER'
        }else{
          this.$toast.add({severity:'success', summary: 'Pedido confirmado', detail:'O pedido foi confirmado com sucesso', life: 3000});
          this.pedidoData.status = 'CO'
          this.pedidoData.stserp = 'CO'
          this.pedidoLogData = pedData
        }
        this.loadingData = false
      })
    },


    excluiPedido(){
      console.log('confPedido')

      this.$confirm.require({
        group: 'dialog',
        header: 'Exclusão',
        message: 'Após excluir o pedido não poderá mais ser visualizado, tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.loadingData = true
          this.pedidoService.excluirPed({
            'pedido': this.pedidoData
          }).then((pedData) => {
            console.log('resultado excluiped')
            console.log(pedData)
            this.$router.replace({ path: '/pedidos' }).then(() => {
              this.$toast.add({severity:'success', summary: 'Pedido excluído', detail:'O pedido foi excluído com sucesso', life: 3000});
            })
          })
        },

      });

    },


    confPedido(){
      console.log('confPedido')


      let validaItens = true
      this.pedItensData.forEach(peditem => {
        // console.log(peditem)
        if ((!peditem.quant || peditem.quant <= 0) || (!peditem.vlrunit || peditem.vlrunit <= 0) || (!peditem.vlrtotal || peditem.vlrtotal <= 0)){
          validaItens = false
        }
      })
      // console.log('validaItens')
      // console.log(validaItens)

      var valMinCliente = parseFloat(this.clienteData.livre2)
      var valorMinPedido = 2000.00
      console.log('this.clienteData.livre2')
      console.log(valMinCliente)
      if (valMinCliente > 0){
        valorMinPedido = valMinCliente
      }


      if (this.clienteData.status === 'I'){
        this.$toast.add({severity:'warn', summary: 'Validação Cliente', detail: 'Pedido não pode ser confirmado para cliente com status Inativo, favor corrigir e confirmar novamente.', life: 5000});
      }else{
        if (!validaItens){
          this.$toast.add({severity:'warn', summary: 'Validação Itens Pedido', detail: 'O pedido possui algum produto com quantidade ou valor igual a zero, favor corrigir e confirmar novamente.', life: 5000});
        } else {
          this.validConfPedido = true

          if (this.pedidoData.condpgto && this.pedidoData.repres) {
            if (this.pedidoData.tipo === '01' && this.pedidoData.valor < valorMinPedido) {
              this.$toast.add({
                severity: 'warn',
                summary: 'Valor Mínimo Pedido',
                detail: 'Valor mínimo de pedido (R$ ' + this.formatDecimal(valorMinPedido) + ') não atingido, favor corrigir e confirmar novamente.',
                life: 5000
              });
            } else {
              this.$confirm.require({
                group: 'dialog',
                header: 'Confirmação',
                message: 'Após confirmação o pedido não poderá mais ser alterado, tem certeza que deseja confirmar?',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: () => {
                  this.loadingData = true
                  this.pedidoService.confirmarPed({
                    'pedido': this.pedidoData,
                    'reenvio': false
                  }).then((pedData) => {
                    console.log('resultado confped')
                    console.log(pedData)
                    if (pedData[0] === 'Erro WS Protheus') {
                      this.$toast.add({
                        severity: 'warn',
                        summary: 'Problemas com WS Protheus',
                        detail: pedData[1],
                        life: 5000
                      });
                      // this.pedidoData.status = 'CO'
                      // this.pedidoData.stserp = 'ER'
                      this.pedidoLogData = pedData[1]
                    } else {
                      this.$toast.add({
                        severity: 'success',
                        summary: 'Pedido confirmado',
                        detail: 'O pedido foi confirmado com sucesso',
                        life: 3000
                      });
                      this.pedidoData.status = 'CO'
                      this.pedidoData.stserp = 'CO'
                      this.pedidoLogData = pedData
                    }
                    this.loadingData = false
                  })
                },
                reject: () => {
                }
              });
            }
          }
        }
      }

    },


    calculaTotalPedido() {
      console.log('calculaTotalPedido')
      console.log(this.loadingData)

      if (this.pedidoData && this.pedidoData.valorbruto > 0) {

        var valorIni = this.pedidoData.valorbruto
        var valorFim = this.pedidoData.vlrliqitens

        if (this.pedidoData.destipo > 0) {
          this.valorDescPedTipo = valorIni * this.pedidoData.destipo / 100
          valorFim = valorIni - (valorIni * this.pedidoData.destipo / 100)
        } else {
          this.valorDescPedTipo = 0
        }

        if (this.pedidoData.descond > 0) {
          this.valorDescCond = valorFim * this.pedidoData.descond / 100
          valorFim = valorFim - (valorFim * this.pedidoData.descond / 100)
        } else {
          this.valorDescCond = 0
        }

        if (this.pedidoData.desconto > 0) {
          this.valorDescManga = valorFim * this.pedidoData.desconto / 100
          valorFim = valorFim - this.valorDescManga
        } else {
          this.valorDescManga = 0.00
        }

        var totalDesconto = (this.valorDescPedTipo + this.valorDescCond + this.valorDescManga)
        console.log('valorIni')
        console.log(valorIni)
        console.log('destipo' + this.pedidoData.destipo)
        console.log('descond' + this.pedidoData.descond)
        console.log('manga' + this.pedidoData.desconto)
        console.log(totalDesconto)

        var totalbrutoItens = 0
        var totalliquidoItens = 0
        if (totalDesconto > 0) {
            this.percDescPraticado = totalDesconto / valorIni * 100
            this.pedItensData.forEach(peditem => {
                var valorLiqItemFinal = peditem.vlrtotal
                if (this.pedidoData.destipo > 0) {
                    valorLiqItemFinal = valorLiqItemFinal - (valorLiqItemFinal * this.pedidoData.destipo / 100)
                }
                if (this.pedidoData.descond > 0) {
                    valorLiqItemFinal = valorLiqItemFinal - (valorLiqItemFinal * this.pedidoData.descond / 100)
                }
                if (this.pedidoData.desconto > 0) {
                    valorLiqItemFinal = valorLiqItemFinal - (valorLiqItemFinal * this.pedidoData.desconto / 100)
                }
                peditem.vlrliqped = valorLiqItemFinal

                totalbrutoItens = totalbrutoItens + peditem.vlrbruto
                totalliquidoItens = totalliquidoItens + peditem.vlrtotal
                peditem.vlripi = peditem.vlrliqped * peditem.percipi / 100
            })
        } else {
            this.percDescPraticado = 0.00
            this.pedItensData.forEach(peditem => {
                peditem.vlrliqped = peditem.vlrtotal
                totalbrutoItens = totalbrutoItens + peditem.vlrbruto
                totalliquidoItens = totalliquidoItens + peditem.vlrtotal
                peditem.vlripi = peditem.vlrliqped * peditem.percipi / 100
          })
        }

        console.log('totalDescItens')
        // console.log(this.percDescPraticado)
        console.log(totalbrutoItens)
        console.log(totalliquidoItens)
        this.percDescItens = (100.00 - (totalliquidoItens / totalbrutoItens * 100)).toFixed(2)
        console.log('percDescItens')
        console.log(this.percDescItens)

        if (this.pedidoData.tipo == '01') {
          if (this.percDescItens <= 0.00) {
            this.percComissao = 5.00
            this.faixaComissao = 'Faixa 1'
          } else if (this.percDescItens > 0.01 && this.percDescItens <= 3.00) {
            this.percComissao = 4.00
            this.faixaComissao = 'Faixa 2'
          } else if (this.percDescItens > 3.01 && this.percDescItens <= 5.90) {
            this.percComissao = 3.00
            this.faixaComissao = 'Faixa 3'
          } else if (this.percDescItens > 5.90 && this.percDescItens <= 8.70) {
            this.percComissao = 2.00
            this.faixaComissao = 'Faixa 4'
          } else if (this.percDescItens > 8.70 && this.percDescItens <= 11.50) {
            this.percComissao = 1.00
            this.faixaComissao = 'Faixa 5'
          } else {
            this.percComissao = 0.00
            this.faixaComissao = ''
          }

          this.valorComissao = valorFim * this.percComissao / 100
        }

        this.valorBrtPed = valorIni
        this.valorLiqPed = valorFim

      } else {
        this.valorBrtPed = 0.00
        this.valorLiqPed = 0.00
      }
    },

    imprimitPDF(){
      this.loadingPDF = true

      this.pedidoService.getPedPDF(this.pedidoData.id).then(() => {
        this.loadingPDF = false
      })

    },

    calculaTotalItem(){
      console.log('calculaTotalItem')
      // console.log(this.pedItemData.quant)
      // console.log(this.pedItemData.vlrunit)
      // console.log(this.pedItemData.vlrtotal)

      let quant = this.pedItemData.quant
      let vlrunit = this.pedItemData.vlrtab
      let desconto = this.pedItemData.desconto

      // this.percComissaoItem = 32

      if (quant > 0 && vlrunit > 0){
        let vlrtotal = quant * vlrunit
        if (desconto){
          let vlrdesc = vlrtotal * desconto / 100
          vlrtotal = vlrtotal - vlrdesc

          if (desconto <= 0.00){
            this.percComissaoItem = 5.00
          }else if (desconto > 0.01 && desconto <= 3.00) {
            this.percComissaoItem = 4.00
          }else if (desconto > 3.01 && desconto <= 5.90) {
            this.percComissaoItem = 3.00
          }else if (desconto > 5.90 && desconto <= 8.70) {
            this.percComissaoItem = 2.00
          }else if (desconto > 8.70 && desconto <= 11.50) {
            this.percComissaoItem = 1.00
          }else{
            this.percComissaoItem = 0.00
          }

        }else{
          this.percComissaoItem = 5.00
        }

        this.pedItemData.vlrunit = (vlrtotal / quant).toFixed(4)
        this.pedItemData.vlrtotal = vlrtotal.toFixed(4)
        this.pedItemData.peso = (this.pedItemData.quant * this.produtoData.pesobrt).toFixed(4)
        this.pedItemData.vlrbruto = (quant * this.pedItemData.vlrtab).toFixed(4)

        // this.pedItemData.vlrtotalliq = this.pedItemData.vlrbruto

        // var valorIni = this.pedItemData.vlrbruto
        var valorFim = this.pedItemData.vlrtotal

        if (this.pedidoData.destipo > 0){
          valorFim = valorFim - (valorFim * this.pedidoData.destipo / 100)
        }

        if (this.pedidoData.descond > 0){
          valorFim = valorFim - (valorFim * this.pedidoData.descond / 100)
        }

        if (this.pedidoData.desconto > 0){
          valorFim = valorFim - (valorFim * this.pedidoData.desconto / 100)
        }
        this.pedItemData.vlrtotalliq = valorFim
        this.pedItemData.vlrunliq = valorFim / quant

      }

    },

    nomeStatus(sigla){

      switch (sigla) {
        case 'EX':
          return 'excluído'

        case 'EL':
          return 'Elaboração'

        case 'CO':
          return 'Confirmado'

        case 'FA':
          return 'Faturado'

        case 'SC':
          return 'Sucesso'

        case 'RO':
          return 'Romaneio'

        case 'X':
          return 'Cancelado'

        default:
          return 'Não Definido'

      }
    },

    nomeStatusERP(sigla){

      switch (sigla) {
        case 'ER':
          return 'Erro Integração'

        case 'CO':
          return 'Confirmado ERP'

        default:
          return 'Não Definido'

      }
    },



    searchRepres(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredRepres = [...this.autoValueRepres];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqRepres(event.query).then((data) => {
            // console.log('getPesqRepres')
            // console.log(data)
            this.autoValueRepres = data
            this.autoFilteredRepres = this.autoValueRepres.filter((repres) => {
              if (repres.descricao && event.query){
                // console.log(codtab.nome)
                // return repres.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return repres.descricao;
              }
            });
          });
        }
      }, 250);
    },

    searchCodTab(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCodTab = [...this.autoValueCodTab];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqCodTab(event.query).then((data) => {
            // console.log('getPesqCodTab')
            // console.log(data)
            this.autoValueCodTab = data
            this.autoFilteredCodTab = this.autoValueCodTab.filter((codtab) => {
              if (codtab.descricao && event.query){
                // console.log(codtab.nome)
                // return codtab.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return codtab.descricao;
              }
            });
          });
        }
      }, 250);
    },


    searchTransp(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredTransp = [...this.autoValueTransp];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqTransp(event.query).then((data) => {
            // console.log('getPesqTransp')
            // console.log(data)
            this.autoValueTransp = data
            this.autoFilteredTransp = this.autoValueTransp.filter((transp) => {
              if (transp.descricao && event.query){
                // console.log(transp.nome)
                // return transp.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return transp.descricao;
              }
            });
          });
        }
      }, 250);
    },

  searchTranspRedesp(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredTranspRedesp = [...this.autoValueTranspRedesp];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqTransp(event.query).then((data) => {
            // console.log('getPesqTransp')
            // console.log(data)
            this.autoValueTranspRedesp = data
            this.autoFilteredTranspRedesp = this.autoValueTranspRedesp.filter((transp) => {
              if (transp.descricao && event.query){
                // console.log(transp.nome)
                // return transp.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return transp.descricao;
              }
            });
          });
        }
      }, 250);
    },

    searchPedItem(event){
      // setTimeout(() => {
      //   if (!event.query.trim().length) {
      //     this.autoFilteredPedItem = [...this.autoValuePedItem];
      //   }
      //   else {
          console.log('searchPedItem_0')
          console.log(event)

          if (event.query.length >= 5) {
            this.pedidoService.getPesqPedItem({
              "query": event.query,
              "codtab": this.pedidoData.codtab,
            }).then((data) => {
              // console.log('getPesqPedItem')
              // console.log(data)
              this.autoValuePedItem = data
              this.autoFilteredPedItem = this.autoValuePedItem.filter((peditem) => {
                if (peditem.descricao && event.query) {
                  // console.log(peditem.nome)
                  // return peditem.nome.toLowerCase().startsWith(event.query.toLowerCase());
                  return peditem.descricao;
                }
              });
            });
          }
        // }
      // }, 10);
    },

    searchPedTipo(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredPedTipo = [...this.autoValuePedTipo];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqPedTipo(event.query).then((data) => {
            // console.log('getPesqPedTipo')
            // console.log(data)
            this.autoValuePedTipo = data
            this.autoFilteredPedTipo = this.autoValuePedTipo.filter((pedtipo) => {
              if (pedtipo.descricao && event.query){
                // console.log(pedtipo.nome)
                // return pedtipo.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return pedtipo.descricao;
              }
            });
          });
        }
      }, 250);
    },

    searchForma(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredForma = [...this.autoValueForma];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqForma(event.query).then((data) => {
            // console.log('getPesqCliente')
            // console.log(data)
            this.autoValueForma = data
            this.autoFilteredForma = this.autoValueForma.filter((forma) => {
              if (forma.descricao && event.query){
                // console.log(pessoa.nome)
                // return pessoa.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return forma.descricao;
              }
            });
          });
        }
      }, 250);
    },

    searchCondicao(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCondicao = [...this.autoValueCondicao];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqCondicao(event.query).then((data) => {
            // console.log('getPesqCliente')
            // console.log(data)
            this.autoValueCondicao = data
            this.autoFilteredCondicao = this.autoValueCondicao.filter((condicao) => {
              if (condicao.descricao && event.query){
                // console.log(pessoa.nome)
                // return pessoa.nome.toLowerCase().startsWith(event.query.toLowerCase());
                return condicao.descricao;
              }
            });
          });
        }
      }, 250);
    },

    searchCliente(event){
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCliente = [...this.autoValueCliente];
        }
        else {
          // console.log('vai pesquisar')
          if (event.query.length >= 3){
            this.pedidoService.getPesqCliente(event.query).then((data) => {
              // console.log('getPesqCliente')
              // console.log(data)
              this.autoValueCliente = data
              this.autoFilteredCliente = this.autoValueCliente.filter((pessoa) => {
                if (pessoa.nome && event.query){
                  // console.log(pessoa.nome)
                  // return pessoa.nome.toLowerCase().startsWith(event.query.toLowerCase());
                  return pessoa.nome;
                }
              });
            });
          }
        }
      }, 250);
    },

    selecionaRepres(event) {
      console.log('selecionaRepres')
      console.log(event.value)
      this.pedidoData['repres'] = event.value.codexterno
    },

    selecionaCodTab(event) {
      console.log('selecionaCodTab')
      console.log(event.value)
      this.pedidoData['codtab'] = event.value.codexterno
    },

    selecionaPedItem(event) {
      console.log('selecionaPedItem')
      console.log(event.value.codexterno)
      console.log(event.value.preco)
      this.pedItemData['produto'] = event.value.codexterno
      this.pedItemData['descproduto'] = event.value.descricao
      this.pedItemData['und'] = event.value.unidade
      this.pedItemData['vlrtab'] = event.value.preco
      this.pedItemData['vlrunit'] = event.value.preco
      this.pedItemData['percipi'] = event.value.ipi
      this.produtoData['pesobrt'] = event.value.pesobrt
    },

    selecionaCliente(event) {
      console.log('selecionaCliente')
      console.log(event.value)
      console.log('event.value.proprecos')
      console.log(event.value.proprecos)

      if (event.value.status === 'I'){
        this.$toast.add({severity:'warn', summary: 'Validação Cliente', detail:'Não é possível realizar pedido para cliente com status Inativo.', life: 3000});
        this.selectedAutoValueCliente = null
      }else{
        this.pedidoData['cliente'] = event.value.codexterno
        this.clienteData['limite'] = event.value.limite
        this.clienteData['livre2'] = event.value.livre2
      }


      if (this.pedtipoData.tipo === 'S') {
        // tabela de preço definida na operacao e nao no cliente
        var codConsultar = ''
        if (event.value.proprecos) {
          this.pedidoData['codtab'] = event.value.proprecos
          codConsultar = event.value.proprecos
          this.clienteData['proprecos'] = codConsultar

          console.log('codConsultar')
          console.log(codConsultar)

          if (codConsultar){
            this.pedidoService.getTabPrecoCod(codConsultar).then((data) => {
              console.log('tabpreco')
              console.log(data[0])
              this.tabprecoData = data[0]
              this.pedidoData['codtab'] = data[0].codexterno
              this.selectedAutoValueCodTab = this.tabprecoData.descricao
            });
          }

        }

      }

    },

    selecionaForma(event) {
      console.log('selecionaForma')
      console.log(event.value)
      this.pedidoData['formapgto'] = event.value.codexterno
    },

    selecionaTransp(event) {
      console.log('selecionaTransp')
      console.log(event.value)
      this.pedidoData['transp'] = event.value.codexterno
    },

    selecionaTranspRedesp(event) {
      console.log('selecionaTranspRedesp')
      console.log(event.value)
      this.pedidoData['transpred'] = event.value.codexterno
    },

    selecionaPedTipo(event) {
      console.log('selecionaPedTipo')
      console.log(event.value.codtab)
      this.pedidoData['tipo'] = event.value.codexterno
      this.pedtipoData['desconto'] = event.value.desconto
      this.pedidoData['destipo'] = event.value.desconto

      if (event.value.codtab !== this.pedidoData['codtab']){
        let codTab = event.value.codtab
        this.pedidoData['codtab'] = codTab
        //console.log(this.)
        this.pedidoService.getTabPrecoCod(codTab).then((data) => {
          console.log('tabpreco')
          console.log(data[0])
          this.tabprecoData = data[0]
          this.selectedAutoValueCodTab = this.tabprecoData.descricao
        });
      }

      if (event.value.codexterno && event.value.codexterno === '29'){
        let codCond = "002"

        this.pedidoService.getCondPgtoCod(codCond).then((data) => {
          console.log('condpgto')
          console.log(data)
          if (data.length > 0){
            this.condpgtoData = data[0]
            this.pedidoData['condpgto'] = this.condpgtoData.codexterno
            this.selectedAutoValueCondicao = this.condpgtoData.descricao
          }
          //this.tabprecoData = data[0]
          //this.selectedAutoValueCodTab = this.tabprecoData.descricao
        });
      }


      this.calculaTotalPedido()
    },

    selecionaCondicao(event) {
      console.log('selecionaCondicao')
      console.log(event.value)
      this.pedidoData['condpgto'] = event.value.codexterno
      this.condpgtoData['desconto'] = event.value.desconto
      this.pedidoData.descond = this.condpgtoData['desconto']
    },

    getOcorrencias(idpedido){
      this.pedidoService.getsscliocors(idpedido).then((data) => {
        console.log('this.ocorsData')
        this.ocorsData = data
        this.loadingOcors = false
        console.log(this.ocorsData)
        this.ocorsData.forEach(ocor => ocor.created_at = new Date(ocor.created_at));
      });
    },

    excluirOcor(){
      console.log('excluirOcor')

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o registro?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          this.pedidoService.deletePedItem(this.pedItemData.id).then((itens) => {
            this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000});
            this.sidebarPedItem = false
            this.pedItensData = itens[0]
            this.pedidoData.valorbruto = itens[1]
            this.calculaTotalPedido()
          })
        },
        reject: () => {

        }
      });


    },

    abrirPedItem(idPedItem){
      console.log('abrirPedItem')
      console.log(idPedItem)

      // this.pedItemData.id

      this.pedidoService.getPedItem(idPedItem).then((data) => {
        // console.log('getPedItem')
        this.pedItemData = data[0]
        this.produtoData = data[1]
        // this.pedItensData['']
        this.selectedAutoValuePedItem = this.pedItemData.descproduto
        this.sidebarPedItem = true
      });
    },

    savePedItem() {
      console.log('savePedItem')
      console.log(this.pedItemData)
      this.validPedItem = true
      this.loadingBtnSaveItem = true

      if ((this.pedItemData.produto && this.pedItemData.quant && this.pedItemData.vlrunit && this.pedItemData.vlrunit > 0 && this.pedItemData.vlrtotal && this.pedItemData.vlrtotal > 0)
          && (this.pedidoData.tipo !== '29' || (this.pedidoData.tipo === '29' && this.pedItemData.mtvassist))
      ){
        this.pedidoService.savePedItem({
          'peditem': this.pedItemData,
          'pedido': this.pedidoData
        }).then((dataPedeItens) => {
          // console.log('savessocor')
          // console.log(dataOcor)

          if (this.pedidoData.id === 0){
            this.pedidoData.id = dataPedeItens[0].id
            this.$router.replace({ path: '/pedidodetalhe/' + this.pedidoData.id }).then(() => {
              this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
            })
          }else{
            this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          }

          this.pedidoData = dataPedeItens[0]
          this.pedItensData = dataPedeItens[1]
          this.loadingBtnSaveItem = false
          this.sidebarPedItem = false

          this.calculaTotalPedido()
          this.validPedItem = false

        });
      }else{
        this.loadingBtnSaveItem = false
      }
    },

    novoProduto(){


      if (!this.pedidoData.cliente) {
        this.$toast.add({severity:'warn', summary: 'Cliente', detail:'Favor selecionar o cliente antes de adicionar produtos ao pedido', life: 3000});
      }else if (!this.pedidoData.codtab) {
        this.$toast.add({severity:'warn', summary: 'Tabela de Preços', detail:'Favor selecionar a tabela de preços antes de adicionar produtos ao pedido', life: 3000});
      }else{
        console.log('novoProduto')
        this.pedItemData = {
          'tabpreco': this.pedidoData.codtab,
          'pedido_id': this.pedidoData.id
        }
        this.selectedAutoValuePedItem = null
        this.validPedItem = false
        this.sidebarPedItem = true
      }
    },

    excluirFile(arquivo){
      console.log('excluirFile')
      console.log(arquivo.id)

      this.$confirm.require({
        group: 'dialog',
        header: 'Confirmação',
        message: 'Tem certeza que deseja deletar o arquivo?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        accept: () => {
          console.log('confirmou')

          this.pedidoService.ssdeletefile(arquivo.id).then((data) => {
            console.log('data delete file')
            console.log(data)


            if (arquivo.tipo === 'conta_energia'){
              this.arqContaEnergia = null
            } else if (arquivo.tipo === 'relogio'){
              this.arqRelogio = null
            } else if (arquivo.tipo === 'quadro_distribuicao'){
              this.arqQuadroDistribuicao = null
            } else if (arquivo.tipo === 'disjuntor_geral'){
              this.arqDisjuntorGeral = null
            } else if (arquivo.tipo === 'telhado'){
              this.arqTelhado = null
            } else if (arquivo.tipo === 'faixada'){
              this.arqFaixada = null
            }


            this.$toast.add({severity:'success', summary: 'Arquivo excluído', detail:'As informações foram excluídas com sucesso', life: 3000});

          })


        },
        reject: () => {

        }
      });
    },


    downloadFile(arquivo){
      console.log('downloadFile')
      console.log(arquivo)

      window.open(api.getUri() + 'uploads/'+ arquivo, '_blank');
    },

    atualizaListaImages(){
      this.pedidoService.sspeslocimg(this.localData.id).then((data) => {
        // console.log('images')
        // console.log(data)
        data.forEach(arquivo => {
          if (arquivo.tipo === 'conta_energia'){
            this.arqContaEnergia = arquivo
          } else if (arquivo.tipo === 'relogio'){
            this.arqRelogio = arquivo
          } else if (arquivo.tipo === 'quadro_distribuicao'){
            this.arqQuadroDistribuicao = arquivo
          } else if (arquivo.tipo === 'disjuntor_geral'){
            this.arqDisjuntorGeral = arquivo
          } else if (arquivo.tipo === 'telhado'){
            this.arqTelhado = arquivo
          } else if (arquivo.tipo === 'faixada'){
            this.arqFaixada = arquivo
          }
        })
        this.arquivos = data
      })
    },

    beforeUpload(request){
      console.log('beforeUpload')
      //console.log(tipo)

      request.formData.append('origem', 'pessoaLocal')
      request.formData.append('origemId', this.localData.id)
      request.formData.append('tipo', this.arqSubindo)

      const token = localStorage.getItem("token");

      if (token) {
        //config.headers.Authorization = `Bearer ${token}`;
        request.xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      }

      //console.log(event.formData)
      return request
    },

    onUpload() {
      console.log('onUpload')
      this.atualizaListaImages()
      this.$toast.add({severity:'success', summary: 'Arquivo gravado', detail:'As informações foram atualizadas com sucesso', life: 3000});

    },

    selecionaCidade(event){
      console.log('selecionaPedido')
      // console.log(event.value)
      this.pedidoData['cidade'] = event.value.nome
      this.pedidoData['estado'] = event.value.estado
    },

    searchCidade(event){
      console.log('searchCidade')
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.autoFilteredCidade = [...this.autoValueCidade];
        }
        else {
          // console.log('vai pesquisar')
          this.pedidoService.getPesqCidade().then((data) => {
            // console.log('getPesqCidade')
            // console.log(data)
            this.autoValueCidade = data
            this.autoFilteredCidade = this.autoValueCidade.filter((cidade) => {
              return cidade.nome.toLowerCase().startsWith(event.query.toLowerCase());
            });
          });

        }
      }, 250);
    },

    pesquisarCEP() {
      if (!this.pedidoData.cep) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de cep inválido, favor corrigir', life: 3000});
      }else{
        this.loadingCEP = true
        let cepFormated = this.pedidoData.cep.replace('.','').replace('-', '');
        this.pedidoService.getPesquisaCEP(cepFormated).then((dataCEP) => {
          console.log('getPesquisaCEP')
          console.log(dataCEP)

          if (dataCEP.cep){
            this.pedidoData.bairro = dataCEP.bairro
            this.pedidoData.compl = dataCEP.complemento
            this.pedidoData.cidade = dataCEP.localidade
            this.selectedAutoValueCidade = dataCEP.localidade
            this.pedidoData.rua = dataCEP.logradouro
            this.pedidoData.estado = dataCEP.uf
          }else{
            this.$toast.add({severity:'warn', summary: 'Validação', detail:'Retorno de consulta inválido, favor preencher manualmente os dados', life: 3000});
          }
          this.loadingCEP = false
        });
      }


    },

    enviarEmail(){
      if (!this.pedidoData.email) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Formato de e-mail inválido, favor corrigir', life: 3000});
      }else{
        window.open("mailto:"+this.pedidoData.email);
      }
    },

    enviarZap(){
      if (!this.pedidoData.telefone) {
        this.$toast.add({severity:'warn', summary: 'Validação', detail:'Tamanho do número de telefone inválido, favor corrigir', life: 3000});
      }else{
        window.open('https://api.whatsapp.com/send?phone=5527999722245', '_blank');
      }
    },

    loadBtn(index) {
      this.loadingBtn[index] = true;
      setTimeout(() => this.loadingBtn[index] = false, 1000);
    },

    savePedido() {
      console.log('savePedido')
      console.log(this.pedidoData)

      this.validPedido = true

      if (this.pedidoData.cliente && this.pedidoData.condpgto){
        this.loadingBtnSave = true
        this.pedidoData.valor = this.valorLiqPed
        this.pedidoData.comiss = this.valorComissao
        this.pedidoService.savePedido({
          'pedido': this.pedidoData
        }).then((dataPed) => {
          console.log('savesspedido')
          console.log(dataPed)
          this.loadingBtnSave = false

          if (this.pedidoData.id === 0){
            this.pedidoData.id = dataPed.id
            this.$router.replace({ path: '/pedidodetalhe/' + this.pedidoData.id }).then(() => {
              this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
            })
          }else{
            this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          }
        });
      }
    },


    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
